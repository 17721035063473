main {
  min-height: 80vh;
}

footer {
  min-height: 20vh;
  padding-top: 4rem;
}

.version {
  font-size: xx-small;
}

.titlesection {
  padding: 3em 0 0 0;
}

.titlesection h1 {
  text-align: center;
}


.password{
  font-family: 'Source Code Pro', monospace;
  
}

/* Range Slider as a Single Thumb Slider */
.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}


.range-slider.grad {
  --progress-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2) inset;
  --progress-flll-shadow: var(--progress-shadow);
  --fill-color: linear-gradient(to right, LightCyan, var(--primary-color));
  --thumb-shadow: 0 0 4px rgba(0, 0, 0, 0.3),
    -3px 9px 9px rgba(255, 255, 255, 0.33) inset,
    -1px 3px 2px rgba(255, 255, 255, 0.33) inset,
    0 0 0 99px var(--primary-color) inset;

  input {
    &:hover {
      --thumb-transform: scale(1.2);
    }

    &:active {
      --thumb-shadow: inherit;
      --thumb-transform: scale(1);
    }
  }
}
